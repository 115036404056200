import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {
  API_URL_VALIDATION,
  API_URL_POST_LANES,
} from "../../configuration/Configuration";

const Validation = (props) => {
  const [lanesData, setLanesData] = React.useState({});
  const [fileData, setFileData] = React.useState([""]);
  const [isPosting, setIsPosting] = React.useState(false);

  const [validateState, setValidateState] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  // reset states before component leave
  const handleUnload = () => {
    setValidateState(false);
    setFileData([""]);
    setLanesData({});
  };

  const isValidated = (state) => {
    return state;
  };

  const handleValidate = async () => {
    lanesData["data"] = props.mapData;
    lanesData["tenderID"] = props.tender;
    await axios
      .post(API_URL_VALIDATION, lanesData)
      .then((res) => setFileData(res.data))
      .then(console.log("validated file", fileData));
    setValidateState(true);
  };

  const handlePosting = async () => {
    setIsPosting(true);
    lanesData["tenderID"] = props.tender;
    props.func(true);
    await axios.post(API_URL_POST_LANES, lanesData);
    setIsPosting(false);
    
   // props.handleNext();
  };

  const getListLength = (array) => {
    return array.length;
  };

  return (
    <Grid container spacing={2} justify="flex-end" alignItems="center">
      <Grid item xs={5.5}>
        <Typography align="left" sx={{ mt: 2, mb: 1 }}>
          <strong>
            Check if all the provided information can be processed.
          </strong>
        </Typography>
        <Typography align="left" sx={{ mt: 2, mb: 1 }}>
          <strong>
            {" "}
            Please SAVE the lanes before finishing the new tender process.
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5.5}></Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        {isValidated(validateState) && (
          <Box
            component="nav"
            sx={{ border: 1, borderRadius: "5px", paddingInline: "60px" }}
          >
            <Typography
              sx={{ mt: 2, textAlign: "left", fontSize: 14, color: "#a2232b" }}
            >
              {getListLength(fileData) > 2 &&
                `You are almost done! ${getListLength(fileData) - 1} findings.`}
              {getListLength(fileData) <= 2 &&
                "You are almost done! Findings: "}
            </Typography>
            {
              <List>
                {fileData.map((text, index) => (
                  <ListItem disablePadding>{text}</ListItem>
                ))}
              </List>
            }
          </Box>
        )}
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={2}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          color="inherit"
          sx={{ width: "80%" }}
          onClick={handleValidate}
          name="validate"
          variant="outlined"
          size="large"
        >
          Validate
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ flex: "1 1 auto" }} />
        {isValidated(validateState) && (
          <Button
            sx={{ width: "80%" }}
            onClick={handlePosting}
            variant="contained"
            size="large"
          >
            Save
          </Button>
        )}
        {!isValidated(validateState) && (
          <Button
            disabled
            color="inherit"
            sx={{ width: "80%" }}
            variant="outlined"
            size="large"
          >
            Save
          </Button>
        )}
      </Grid>

      <Grid item xs={3}></Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        {isPosting && <CircularProgress></CircularProgress>}
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={4}></Grid>
    </Grid>
  );
};
export default Validation;
