import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/de";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { marks, API_URL_NEW_TENDER } from "../../configuration/Configuration";
import Checkbox from "@mui/material/Checkbox";
import ColumnCreator from "../custom_column/ColumnCreator";
//var tenderStatus = false;

const TenderData = (props) => {
  const [date, setDate] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [date2, setDate2] = React.useState("");
  const [date3, setDate3] = React.useState("");

  const [state, setState] = React.useState("");
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const [state2, setState2] = React.useState("");
  const handleStateChange2 = (event) => {
    setState2(event.target.value);
  };
  const [state3, setState3] = React.useState("");
  const handleStateChange3 = (event) => {
    setState3(event.target.value);
  };

  const [state4, setState4] = React.useState("");
  const handleStateChange4 = (event) => {
    setState4(event.target.value);
  };
  const [state5, setState5] = React.useState(false);
  const handleStateChange5 = (event) => {
    setState5(!state5);
  };
  const [state6, setState6] = React.useState(false);
  const handleStateChange6 = (event) => {
    setState6(!state6);
  };

  const [state7, setState7] = React.useState(false);
  const handleStateChange7 = (event) => {
    setState7(!state7);
  };
  const [prio, setPrio] = React.useState(2);
  const handlePrioChange = (event) => {
    setPrio(event.target.value);
  };

  const postTender = async () => {
    // Create an object of formData
    const tenderData = new FormData();
    // Update the formData object
    tenderData.append("company", props.customer);
    tenderData.append("priority", prio);

    let int_d = new Date(date["$d"]);
    int_d.setDate(int_d.getDate() + 1);
    if (date !== "") {
      date["$d"] = int_d;
      date["$D"] += 1;
    }
    tenderData.append("internal_deadline", date);
    if (date !== "") {
      int_d.setDate(int_d.getDate() - 1);
      date["$d"] = int_d;
      date["$D"] -= 1;
    }
    if (date1 !== "") {
      int_d = new Date(date1["$d"]);
      int_d.setDate(int_d.getDate() + 1);
      date1["$d"] = int_d;
      date1["$D"] += 1;
    }
    tenderData.append("submission_date", date1);
    if (date1 !== "") {
      int_d.setDate(int_d.getDate() - 1);
      date1["$d"] = int_d;
      date1["$D"] -= 1;
    }
    int_d = new Date(date2["$d"]);
    int_d.setDate(int_d.getDate() + 1);
    date2["$d"] = int_d;
    date2["$D"] += 1;
    tenderData.append("contract_start", date2);
    int_d.setDate(int_d.getDate() - 1);
    date2["$d"] = int_d;
    date2["$D"] -= 1;

    int_d = new Date(date3["$d"]);
    int_d.setDate(int_d.getDate() + 1);
    date3["$d"] = int_d;
    date3["$D"] += 1;
    tenderData.append("contract_end", date3);
    int_d.setDate(int_d.getDate() - 1);
    date3["$d"] = int_d;
    date3["$D"] -= 1;

    tenderData.append("invoicing", state);
    tenderData.append("payment_terms", state2);
    tenderData.append("file_directory", state3);
    tenderData.append("requested_capacity", state4);
    tenderData.append("transit_time_required", state7);
    tenderData.append("additional_costs_required", state5);
    tenderData.append("margin_required", state6);

    await axios
      .post(API_URL_NEW_TENDER, tenderData)
      .then((res) => {
        props.func(res.data);
        props.handleNext();
      })
      .then(console.log("tender posted", tenderData));
  };

  return (
    <>
      
      <Grid
        container
        spacing={3}
        sx={{ mt: 5 }}
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={2} justifyContent="flex-start">
          <Typography align="left" sx={{ mt: 2, mb: 1 }}>
            Tender Priority
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Slider
            aria-label="prioslider"
            valueLabelDisplay="auto"
            onChange={handlePrioChange}
            value={prio}
            sx={{ width: "65%" }}
            step={1}
            marks={marks}
            min={1}
            max={3}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography align="left" sx={{ mt: 2, mb: 1 }}>
            Internal Deadline
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker value={date} onChange={setDate} sx={{ width: "65%" }} />
          </LocalizationProvider>
          {date !== "" && date2 !== "" && date > date2 && (
            <p className="input-error">
              Internal Deadline must be before Contract Start!
            </p>
          )}
          {date !== "" && date1 !== "" && date > date1 && (
            <p className="input-error">
              Internal Deadline must be before Submission Deadline!
            </p>
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Tender Submission Until
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              value={date1}
              onChange={setDate1}
              sx={{ width: "65%" }}
            />
          </LocalizationProvider>
          {date1 !== "" && date2 !== "" && date1 > date2 && (
            <p className="input-error">
              Submission must be before Contract Start!
            </p>
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Contract Start
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              value={date2}
              onChange={setDate2}
              label={"Required"}
              sx={{ width: "65%" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Contract End
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              value={date3}
              onChange={setDate3}
              label={"Required"}
              sx={{ width: "65%" }}
            />
          </LocalizationProvider>
          {date2 !== "" && date3 !== "" && date2 > date3 && (
            <p className="input-error">
              Contract Start must be before Contract End!
            </p>
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Invoicing
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Select
            value={state}
            label=""
            sx={{ width: "65%" }}
            onChange={handleStateChange}
          >
            <MenuItem value={"ImmediateSingle"}>Immediate Single</MenuItem>
            <MenuItem value={"WeeklyCollective"}>Weekly Collective</MenuItem>
            <MenuItem value={"BiweeklyCollective"}>
              Biweekly Collective
            </MenuItem>
            <MenuItem value={"MonthlyCollective"}>Monthly Collective</MenuItem>
            {/* <MenuItem value={"MonthlyIndividually"}>Monthly Individually</MenuItem> */}
            {/* <MenuItem value={"AnnuallyIndividually"}>Annually Individually</MenuItem>
          <MenuItem value={"AnnuallyCollective"}>Annually Collective</MenuItem> */}
          </Select>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Capacity
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Select
            value={state4}
            label=""
            sx={{ width: "65%" }}
            onChange={handleStateChange4}
          >
            {" "}
            <MenuItem value={"weekly"}>Weekly</MenuItem>
            <MenuItem value={"monthly"}>Monthly</MenuItem>
            <MenuItem value={"yearly"}>Yearly</MenuItem>
            <MenuItem value={"percentage"}>Percentage</MenuItem>
            {/* <MenuItem value={"MonthlyIndividually"}>Monthly Individually</MenuItem> */}
            {/* <MenuItem value={"AnnuallyIndividually"}>Annually Individually</MenuItem>
          <MenuItem value={"AnnuallyCollective"}>Annually Collective</MenuItem> */}
          </Select>
        </Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }} align="left">
            Payment Terms
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <TextField
            id="filled-basic"
            onChange={handleStateChange2}
            value={state2}
            type="number"
            label="(in Days)"
            sx={{ width: "65%" }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <ColumnCreator setOpen={props.setOpen}    customColumns={props.customColumns}
        setCustomColumns={props.setCustomColumns}></ColumnCreator>

        <Typography sx={{ mt: 4, mb: 0 }} align="left" fontWeight={600}>
          Configure available columns
        </Typography>
        <Grid item xs={12}></Grid>

        <Grid item xs={2}>
          <Typography sx={{ mt: 0, mb: 1 }} align="left">
            Transit Time
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Checkbox
            onChange={handleStateChange7}
            checked={state7}
            name="transit_time_required"
            sx={{ width: "65%" }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={2}>
          <Typography sx={{ mt: 0, mb: 1 }} align="left">
            Additional Costs
          </Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Checkbox
            onChange={handleStateChange5}
            checked={state5}
            name="additional_costs_required"
            sx={{ width: "65%" }}
          />
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={2} align="left">
          {date2 !== "" &&
            date3 !== "" &&
            date2 <= date3 &&
            date <= date2 &&
            date1 <= date2 &&
            date <= date1 && (
              <Button variant="contained" size="medium" onClick={postTender}>
                Confirm
              </Button>
            )}
          {(date2 === "" ||
            date3 === "" ||
            date2 > date3 ||
            date > date2 ||
            date1 > date2 ||
            date > date1) && (
            <Button variant="contained" size="medium" disabled>
              Confirm
            </Button>
          )}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
};
export default TenderData;
