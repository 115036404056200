import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {
  API_URL_STATUS,
  API_URL_DEPARTMENTS,
  API_URL_COLUMNS,
  API_BASE_URL,
} from "./Configuration";
import React from "react";
import { ModuleRegistry } from "@ag-grid-community/core";
//import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
//ModuleRegistry.registerModules([ SetFilterModule ]);

// get array of column names and put them into ag grid definition schema
export const enrichTableColumns = (data: { columns: any[] }) => {
  const tableColumns = [];
  if (data.columns) {
    data.columns.forEach((value) => {
      tableColumns.push({
        field: value,
        headerName: value,
        editable: false,
        resizable: true,
        filter: true,
      });
    });
  }
  return tableColumns;
};

// transform list into drowndown selection with menu items
export function menuItems(list: any[]) {
  return list.map((column, index) => (
    <MenuItem value={column} key={index}>
      {column}
    </MenuItem>
  ));
}
export function menuItemsDict(dict) {
  return Object.keys(dict).map((key) => (
    <MenuItem value={key} key={key}>
      {dict[key]}
    </MenuItem>
  ));
}

// check for upload or posting statusH
export const isPosted = (state) => {
  return state; //TODO: inline this "identity operator"?
};

function currencyFormatter(currency, sign) {
  if (currency !== null) {
    if (typeof currency !== "number") {
      return currency;
    } else if (currency === -3141) {
      return `X`;
    } else {
      var sansDec = currency.toFixed(2);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${formatted}` + sign;
    }
  } else {
    return currency;
  }
}

function currencyFormatterInt(currency, sign) {
  if (currency !== null) {
    if (typeof currency !== "number") {
      return currency;
    } else if (currency === -3141) {
      return `X`;
    } else {
      var sansDec = currency.toFixed(0);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${formatted}` + sign;
    }
  } else {
    return currency;
  }
}

function distanceFormatter(percent, sign) {
  if (percent !== null) {
    if (typeof percent !== "number") {
      return percent;
    } else {
      var sansDec = percent.toFixed(1);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${formatted}` + sign;
    }
  } else {
    return percent;
  }
}
function percentFormatter(percent, sign) {
  if (percent !== null) {
    if (typeof percent !== "number") {
      return percent;
    } else {
      var sansDec = percent.toFixed(0);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${formatted}` + sign;
    }
  } else {
    return percent;
  }
}

const filterOptionsPrice = {
  suppressAndOrCondition: true,
  filterOptions: [
    "empty",
    "greaterThan",
    "lessThan",
    "inRange",
    {
      displayKey: "Not entered",
      displayName: "Not entered",
      predicate: (_, cellValue) => cellValue === null || cellValue === -3141,
      numberOfInputs: 0,
    },
  ],
};

const fetchMap = async (id) => {
  await axios.get(`${API_BASE_URL}/api/polyline/${id}`).then((res) => {
    const polyline = res.data.polyline;
    var event = new CustomEvent("polyLineCreated", {
      detail: polyline,
    });
    document.dispatchEvent(event);
  });
};

export async function headerColumns(tenderID, overview = false) {
  let tempUser = localStorage.getItem("user");

  let departments = (
    await axios.get(`${API_BASE_URL}/api/departments/${tempUser}`)
  ).data;

  let columns = (await axios.get(API_URL_COLUMNS + tenderID)).data;
  let statusH = (await axios.get(API_URL_STATUS + tenderID)).data;

  let tender = (
    await axios.get(`${API_BASE_URL}/api/tenders_lanes_view/${tenderID}`)
  ).data;

  const userData = (await axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`))
    .data;

  const customColumns = (
    await axios.get(`${API_BASE_URL}/api/tender/custom-columns/${tenderID}`)
  ).data;
  const isAdmin = userData.is_admin;
  const isStaff = userData.is_staff;
  const isRegular = userData.is_regular;
  const filters = localStorage.getItem("filter");

  const pFilters = JSON.parse(filters);

  let header: any[] = [
    {
      field: "originCountry",
      headerName: "Origin\nCountry",
      // filter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        // can be 'windows' or 'mac'
        excelMode: "windows",
      },
      resizable: true,
      sortable: true,
    },
  ];
  if (columns.includes("originCity")) {
    header.push({
      field: "originCity",
      headerName: "Origin\nCity",
      filter: true,
      resizable: true,
      sortable: true,
    });
    columns.splice(columns.indexOf("originCity"), 1);
  }
  header.push({
    field: "originZIP",
    headerName: "Origin\nZIP",
    filter: true,
    resizable: true,
    sortable: true,
  });
  if (columns.includes("destinationCity")) {
    header.push({
      field: "destinationCity",
      headerName: "Destination\nCity",
      filter: true,
      resizable: true,
      sortable: true,
    });
    columns.splice(columns.indexOf("destinationCity"), 1);
  }
  if (columns.includes("palletExchange")) {
    header.push({
      field: "palletExchange",
      headerName: "Pallet\nExchange",
      filter: true,
      width: 140,
      resizable: true,
      sortable: true,
    });
    columns.splice(columns.indexOf("palletExchange"), 1);
  }

  header.push(
    {
      field: "destinationCountry",
      headerName: "Destination\nCountry",
      filter: true,
      resizable: true,
      sortable: true,
    },
    {
      field: "destinationZIP",
      headerName: "Destination\nZIP",
      filter: true,
      resizable: true,
      sortable: true,
    },
    {
      field: "volume",
      headerName: "Volume",
      sortable: true,
      width: 140,
      resizable: true,
      filter: true,
      filterParams: filterOptionsPrice,
    },
    {
      field: "volumeUnit",
      headerName: "Volume Unit",
      sortable: true,
      width: 140,
      resizable: true,
      filter: true,
    }
    //{ field: 'palletExchange', headerName: 'Pallet Exchange', sortable: true, width: 140,resizable:true, filter: true },
  );

  let headerDict = {
    equipment: "Equipment",
    payload: "Payload",
    transitTime: "Transit Time",
    requirements: "Special Requirements",
    oneway: "Oneway / Roundtrip",
    customerLaneID: "Customer Lane ID",
  };

  columns.forEach((value) => {
    header.push({
      field: value,
      headerName: headerDict[value],
      sortable: true,
      filter: true,
      resizable: true,
    });
  });

  if (customColumns.length > 0) {
    customColumns.map((column) => {
      return header.push({
        field: column,
        headerName: column.replace('custom_', ""),
        filter: true,
        resizable: true,
        width: 140,
        sortable: true,
      });
    });
  }
  header.push(
    {
      field: "distance_here",
      headerName: "Distance",
      valueFormatter: (params) => distanceFormatter(params.value, "km"),
      filter: true,
      resizable: true,
      width: 140,

      filterParams: filterOptionsPrice,
      sortable: true,
    },
    {
      field: "tolls",
      headerName: "Tolls",
      width: 140,
      valueFormatter: (params) => currencyFormatter(params.value, "€"),
      filter: true,
      resizable: true,
      filterParams: filterOptionsPrice,
      sortable: true,
    }
  );
  if (statusH === "3") {
    header.push(
      {
        field: "round2",
        width: 140,
        headerName: "Price\nFinal Round 2",
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      },
      {
        field: "customer_abs3",
        width: 140,
        headerName: "Price\nCustomer",
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      }
    );
  } else if (statusH === "2") {
    header.push(
      {
        field: "final",
        headerName: "Price\nFinal Round 1",
        width: 140,
        editable: userData.is_staff || userData.is_admin ? true : false,
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      },
      {
        field: "customer_abs2",
        headerName: "Price\nCustomer",
        width: 140,

        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      }
    );
  } else if (statusH === "1") {
    header.push(
      {
        field: "spot-rate",
        headerName: "Price\nspot-rate",
        width: 140,

        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      },
      {
        field: "contract",
        headerName: "Price\nContract",
        width: 140,

        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      }
    );
  }
  header.push({
    field: "suggested-price-total",
    headerName: "Suggested Price",
    width: 130,

    valueFormatter: (params) => currencyFormatterInt(params.value, "€"),
    filter: true,
    resizable: true,
    filterParams: filterOptionsPrice,
    sortable: true,
  });
  header.push({
    field: "empty-km",
    headerName: "Empty km",
    width: 100,
    editable: true,
    filter: true,
    resizable: true,
    filterParams: filterOptionsPrice,
    sortable: true,
    onCellValueChanged: (params) => {
      const newValue = Number(params.newValue);

      const newSuggestedPrice =
        params.data.suggested_price * (params.data.distance_here + newValue);
      params.data["suggested-price-total"] = newSuggestedPrice;
      params.api.refreshCells({ columns: ["suggested-price-total"] });
    },
  });
  header.push({
    field: "suggested_price",
    headerName: "€ / km",
    width: 100,
    valueFormatter: (params) => currencyFormatterInt(params.value, "€"),
    filter: true,
    resizable: true,
    filterParams: filterOptionsPrice,
    sortable: true,
  });

  if (tender) {
    if (tender[0].transit_time_required) {
      header.push({
        field: "transit_time",
        headerName: "Transit time",
        width: 100,
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: true,
      });
    }
    if (tender[0].additional_costs_required) {
      header.push({
        field: "additional_costs",
        headerName: "Additional Costs",
        width: 100,
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: true,
      });
    }
    header.push({
      field: "profitMargin",
      headerName: "Margin",
      width: 100,
      filter: true,
      resizable: true,
      filterParams: filterOptionsPrice,
      sortable: true,
      editable: true,
    });
  }
  if (!isAdmin) {
    if (statusH === "1") {
      header.push({
        field: "regional" + tempUser.substring(19),
        headerName: "Price\nRegional",
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: true,
        width: 120,
      });
    } else if (statusH === "2" || statusH === "3") {
      header.push({
        field: "regional-" + statusH,
        headerName: "Price\nRegional Round " + statusH,
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: true,
        width: 120,
      });
    }
    header.push({
      field: "capacity",
      headerName: "Capacity",
      filter: true,
      resizable: true,
      filterParams: filterOptionsPrice,
      sortable: true,
      editable: true,
      width: 120,
    });
  } else {
    departments?.forEach((value) => {
      if (statusH !== "1" && statusH !== "R") {
        header.push({
          field: `regional-${statusH}` + " - " + value.name, //+ "round" + statusH,
          headerName: "Price\n" + `Round ${statusH} ` + value.name,
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        });
      } else if (statusH === "1") {
        header.push({
          field: "regional - " + value.name, //+ "round" + statusH,
          headerName: "Regional Price\n" + value.name,
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
          editable: true,
        });
      }

      header.push({
        field: "capacity - " + value.name, //+ "round" + statusH,
        headerName: "Regional Capacity\n" + value.name,
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: true,
      });
    });
    if (statusH !== "1" && statusH !== "R") {
      header.push({
        field: "round" + statusH,
        headerName: "Price\nFinal Round " + statusH,
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      });
    } else if (statusH === "1") {
      header.push({
        field: "final",
        headerName: "Price\nFinal",
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
        editable: userData.is_staff || userData.is_admin ? true : false,
      });
    }
    if (statusH === "R") {
      departments?.forEach((value) => {
        header.push({
          field: "regional" + value["username"],
          headerName: "Regional Price\n" + value["locationInfo"] + " Round 1",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        });
      });
      header.push(
        {
          field: "final",
          headerName: "Price\nFinal Round 1",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
          editable: userData.is_staff || userData.is_admin ? true : false,
        },
        {
          field: "customer_abs2",
          headerName: "Customer Price\n Round 1",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        }
      );
      departments.forEach((value) => {
        header.push({
          field: "regional" + value["username"] + "round2",
          headerName: "Regional Price\n" + value["locationInfo"] + " Round 2",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        });
      });
      header.push(
        {
          field: "round2",
          headerName: "Price\nFinal Round 2",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        },
        {
          field: "customer_abs3",
          headerName: "Customer Price\n Round 2",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        }
      );
      departments.forEach((value) => {
        header.push({
          field: "regional" + value["username"] + "round3",
          headerName: "Regional Price\n" + value["locationInfo"] + " Round 3",
          valueFormatter: (params) => currencyFormatter(params.value, "€"),
          filter: true,
          resizable: true,
          filterParams: filterOptionsPrice,
          sortable: true,
        });
      });
      header.push({
        field: "round3",
        headerName: "Price\nFinal Round 3",
        valueFormatter: (params) => currencyFormatter(params.value, "€"),
        filter: true,
        resizable: true,
        filterParams: filterOptionsPrice,
        sortable: true,
      });
    }
  }

  header.push({
    field: "copy-price",
    headerName: "Set suggested price",
    filter: false,
    resizable: true,
    sortable: false,
    width: 150,
    valueGetter: () => {
      return "Set";
    },
    onCellClicked: (params) => {
      const oldValue = params.data["regional"];
      params.data["regional"] = params.data["suggested-price-total"];
      params.api.refreshCells({ columns: ["regional"] });
      const colDef = params.api.getColumnDef("regional"); // Get the column definition
      params.api.dispatchEvent({
        type: "cellValueChanged",
        data: params.data,
        node: params.node,
        oldValue: oldValue,
        newValue: params.data["regional"],
        colDef: colDef,
        column: params.column,
        rowIndex: params.rowIndex,
        api: params.api,
        columnApi: params.columnApi,
        context: params.api.context,
        rowPinned: false,
      });
    },
    cellClass: "poly-view",
  });

  header.push({
    field: "map",
    headerName: "Route",
    filter: false,
    resizable: true,
    sortable: false,
    width: 120,
    valueGetter: () => {
      return "View";
    },
    onCellClicked: (e) => {
      fetchMap(e.data.id);
    },
    cellClass: "poly-view",
  });
  header[header.length - 1]["editable"] = !overview;

  header = header.map((item) => {
    const correspondingFilter = pFilters?.find(
      (filter) => filter.field === item.field
    );
    if (correspondingFilter && correspondingFilter.pinned) {
      item.pinned = correspondingFilter.pinned;
    }
    if (correspondingFilter && correspondingFilter.hide) {
      item.hide = correspondingFilter.hide;
    }
    return item;
  });
  return header;
}

export const setGridColsFeedback = async (tenderID) => {
  let statusH = (await axios.get(API_URL_STATUS + tenderID)).data;
  let laneColumnDefinitionRound2c: any = [
    {
      field: "originCountry",
      headerName: "Origin\nCountry",
      filter: true,
      resizable: true,
    },
    {
      field: "originZIP",
      headerName: "Origin\nZIP",
      filter: true,
      resizable: true,
    },
    {
      field: "destinationCountry",
      headerName: "Destination\nCountry",
      filter: true,
      resizable: true,
    },
    {
      field: "destinationZIP",
      headerName: "Destination\nZIP",
      filter: true,
      resizable: true,
    },
    {
      field: "volume",
      headerName: "Volume",
    },
    {
      field: "tolls",
      headerName: "Tolls",
      valueFormatter: (params) => currencyFormatter(params.value, "€"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
    },
    {
      field: "distance_here",
      headerName: "Distance",
      valueFormatter: (params) => distanceFormatter(params.value, "km"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
    },
  ];
  if (statusH === "2") {
    laneColumnDefinitionRound2c.push({
      field: "final",
      headerName: "Price\nFinal Round 1",
      valueFormatter: (params) => currencyFormatter(params.value, "€"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
    });
  } else {
    laneColumnDefinitionRound2c.push({
      field: "round2",
      headerName: "Price\nFinal Round 2",
      valueFormatter: (params) => currencyFormatter(params.value, "€"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
    });
  }
  laneColumnDefinitionRound2c.push(
    {
      field: "customer_abs" + statusH,
      headerName: "Price\nCustomer in €",
      valueFormatter: (params) => currencyFormatter(params.value, "€"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
      editable: true,
    },
    {
      wrapText: true,
      field: "customer_rel" + statusH,
      headerName: "Price\nCustomer in %",
      valueFormatter: (params) => percentFormatter(params.value, "%"),
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["greaterThan"],
      },
      editable: true,
    }
  );

  return laneColumnDefinitionRound2c;
};
