import { Chip, Typography, Grid, Button, Modal, Box } from "@mui/material";
import { useState } from "react";
const ColumnCreator = ({ setOpen, customColumns, setCustomColumns }) => {
  const [update, setUpdate] = useState(false);

  const handleDelete = (index) => {
    const newColumns = customColumns;
    newColumns.splice(index, 1);
    setCustomColumns(newColumns);
    setUpdate(!update); // Toggle to trigger re-render
  };

  return (
    <>
      <Typography sx={{ mt: 4, mb: 0 }} align="left" fontWeight={600}>
        Custom Columns
      </Typography>

      <Grid item xs={12} align="left">
        {customColumns &&
          customColumns.length > 0 &&
          customColumns.map((column, index) => {
            return (
              <Chip
                sx={{mr:2}}
                label={column}
                variant="outlined"
                onDelete={() => handleDelete(index)}
              ></Chip>
            );
          })}
      </Grid>
      <Grid item xs={12} align="left">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setOpen(true)}
        >
          Add Column
        </Button>
      </Grid>
    </>
  );
};

export default ColumnCreator;
