import React from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AgGridReact } from 'ag-grid-react';
import Grid from '@mui/material/Grid';
import { isPosted, headerColumns } from '../configuration/Utils';

import { API_URL_TENDER_OVERVIEW, API_URL_LANES_PRICING, tenderColumnsDefinitionOverview, API_URL_CLEANUP, API_URL_TENDERS_DELETE } from "../configuration/Configuration"

import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import TableControls from "../integrations/core/table/TableControls";
import {FullScreen, useFullScreenHandle} from "react-full-screen";


const Admin = () => {
  window.addEventListener("beforeunload", () => { tenders = [] });
  const { key } = useLocation();
  useEffect(() => {
    resetState();
    getTenderDataOverview();
  }, [key]);

  // retrieve tender overview 
  let [tenders, setOverview] = React.useState([]);
  // eslint-disable-next-line
  React.useEffect(() => {
    const opt = [];

    (async () => {
      const { data } = await axios.get(API_URL_TENDER_OVERVIEW);
      data.forEach((value) => {
        opt.push(value);
      });
    })();
    setOverview(opt);
  }, []);

  const getTenderDataOverview = () => {
    (async () => {
      const opt = [];
      const url_api = API_URL_TENDER_OVERVIEW;
      const { data } = await axios.get(url_api);
      data.forEach((value) => {
        opt.push(value);
      });
      setOverview(opt);
    })();
  };

  const gridRef = React.useRef();
  let fullScreenHandle = useFullScreenHandle();
  const gridStyle = React.useMemo(() => ({ height: 540, width: '100%' }), []);

  let [tendersSelectionState, setTenderSelectState] = React.useState(false);
  // eslint-disable-next-line
  let [tendersSelected, setTenderSelected] = React.useState(0);
  const [tenderData, setTenderData] = React.useState([]);
  const [gridAdmin, setGridAdmin] = React.useState([]);
  const getTenderData = (tender) => {
    (async () => {
      const url_api = API_URL_LANES_PRICING + tender + "/admin"
      const { data } = await axios.get(url_api);
      setTenderData(data);

      let laneColumnDefinitionAdmin = await (headerColumns(tender, true))
      setGridAdmin(laneColumnDefinitionAdmin);
    })();
  };
  const onTenderSelected = (params) => {
    setTenderSelected(params.data.id);
    getTenderData(params.data.id);
    setTenderSelectState(true);
  };
  const resetState = () => {
    setTenderSelectState(false);
  };


  const [cleanup, setCleanup] = React.useState(false);
  const [dbKey, setdbKey] = React.useState("");
  const [dbCKey, setdbCKey] = React.useState("");
  const [EXKey, setEXKey] = React.useState("");

  const cleanupButton = () => {
    setCleanup(true);
  };

  const cancelButton = () => {
    setCleanup(false);
    setdbKey("");
    setEXKey("");
    setdbCKey("");
  };

  const handledbKeyChange = (event) => {
    setdbKey(event.target.value);
  };
  const handleEXKeyChange = (event) => {
    setEXKey(event.target.value);
  };
  const handledbCKeyChange = (event) => {
    setdbCKey(event.target.value);
  };

  const cleanDB = async () => {
    let formData = { "keyDB": dbKey }
    await axios.post(API_URL_CLEANUP, formData).then(res => console.log(res.data));
    setCleanup(false);
    setdbKey("");
    setEXKey("");
    setdbCKey("");
    getTenderDataOverview();
  };
  const cleanEX = async () => {
    let formData = { "keyEX": dbKey }
    await axios.post(API_URL_CLEANUP, formData).then(res => console.log(res.data));
    setCleanup(false);
    setdbKey("");
    setEXKey("");
    setdbCKey("");
  };
  const cleanDBC = async () => {
    let formData = { "keyDBC": dbCKey }
    await axios.post(API_URL_CLEANUP, formData).then(res => console.log(res.data));
    setCleanup(false);
    setdbKey("");
    setEXKey("");
    setdbCKey("");
    getTenderDataOverview();
  };

  const deleteTender = async () => {
    console.log("TENDER:", tendersSelected)
    let delData = { "tender_id": tendersSelected };
    if (window.confirm("Do you really want to delete the tender?")) {
      if (window.confirm("Are you really sure?")) {
        setCleanup(false);
        setdbKey("");
        setEXKey("");
        setdbCKey("");
        setTenderSelectState(true);
        await axios.post(API_URL_TENDERS_DELETE, delData).then(res => console.log("Tender Deleted", res.data));
      }
    }
    resetState();
    getTenderDataOverview();
  };

  return (
    <div className="App">
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">

        {/* <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Button variant="outlined" size="large" onClick={getTenderDataOverview} color="inherit" >
            Load Data
          </Button>
        </Grid>
        <Grid item xs={8}></Grid> */}
        <Grid item xs={0.5}></Grid>
        <Grid item xs={11.5}>
          <Typography variant="h6" align="left">Monitor the Status of your Tenders</Typography>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={12}>
          <FullScreen handle={fullScreenHandle}>
            <TableControls table={gridRef} fullScreenHandle={fullScreenHandle}/>
            {isPosted(!tendersSelectionState) && (
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                  rowData={tenders} // Row Data for Rows
                  columnDefs={tenderColumnsDefinitionOverview} // Column Defs for Columns
                  onRowDoubleClicked={onTenderSelected}
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            )}
            {isPosted(tendersSelectionState) && (
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                  rowData={tenderData?.rows} // Row Data for Rows
                  columnDefs={gridAdmin} // Column Defs for Columns
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            )}
          </FullScreen>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          {isPosted(tendersSelectionState) && (
            <Button variant="outlined" size="large" onClick={resetState} color="inherit" >
              Back
            </Button>
          )}
          {isPosted(!tendersSelectionState) && (
            <Button variant="outlined" size="large" disabled color="inherit" >
              Back
            </Button>
          )}
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={0.5}></Grid>
        {!isPosted(cleanup) && (
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={cleanupButton} color="inherit" >
              Cleanup
            </Button>
          </Grid>
        )}
      </Grid>



      {isPosted(cleanup) && (
        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={cancelButton} color="inherit" >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <TextField label="Password Cleanup Database"
              type="password"
              variant="outlined" sx={{ width: '100%' }}
              onChange={handledbKeyChange}
              value={dbKey}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <TextField label="Password Clear Database"
              type="password"
              variant="outlined" sx={{ width: '100%' }}
              onChange={handledbCKeyChange}
              value={dbCKey}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <TextField label="Password Cleanup Export Files"
              type="password"
              variant="outlined" sx={{ width: '100%' }}
              onChange={handleEXKeyChange}
              value={EXKey}
            />
          </Grid>
        </Grid>
      )}
      {isPosted(cleanup) && (
        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}></Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            {isPosted(tendersSelectionState) && (
              <Button variant="outlined" size="large" onClick={deleteTender} color="inherit" >
                delete tender
              </Button>
            )}
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={cleanDB} color="inherit" >
              Cleanup Database
            </Button>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={cleanDBC} color="inherit" >
              Clear Database
            </Button>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={cleanEX} color="inherit" >
              Cleanup Export Files
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Admin;
