import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CustomerData from "./stepper/CustomerData";
import TenderData from "./stepper/TenderData";
import FileUpload from "./stepper/FileUpload";
import Transformation from "./stepper/Transformation";
import Mapping from "./stepper/Mapping";
import Enrichments from "./stepper/Enrichments";
import Validation from "./stepper/Validation";
import { steps, optionalSteps } from "../configuration/Configuration";
import { isPosted } from "../configuration/Utils";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../configuration/Configuration";
import { Modal, TextField } from "@mui/material";
export default function NewTender() {
  const [open, setOpen] = useState(false);

  const [columnTitle, setColumnTitle] = useState();
  const [customColumns, setCustomColumns] = useState([]);

  const [userType, setUser] = useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
  });
  const [isAuth, setIsAuth] = useState(false);
  const { key } = useLocation();
  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);
      let tempUser = localStorage.getItem("user");

      axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`).then((res) => {
        const isAdmin = res.data.is_admin;
        const isStaff = res.data.is_staff;
        const isRegular = res.data.is_regular;
        setUser({ isAdmin, isStaff, isRegular });
      });
    }
  }, [isAuth]);
  useEffect(() => {
    handleReset();
  }, [key]);

  let stepDone = false;
  const [saveLaneState, setSaveLaneState] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  // set upload file columns
  // eslint-disable-next-line
  const [customer, setCustomer] = React.useState("N/A");
  const customerSettings = (customerData) => {
    setCustomer(customerData);
    console.log("setting customer in new tender: ", customer);
  };
  // set tender ID
  const [tender, setTender] = React.useState("");
  const tenderID = (id) => {
    setTender(id);
    console.log("Getting tender-ID: ", tender);
  };
  // set upload file columns and file
  const [uploadFile, setUploadFile] = React.useState({ columns: ["-"] });
  const fileData = (uploadedFile) => {
    setUploadFile(uploadedFile);
    console.log("Setting upload data in new tender: ", uploadFile);
  };

  // set mapped Data
  const [mapData, setMappedData] = React.useState({ columns: [""] });
  const mappedData = (data) => {
    setMappedData(data);
    console.log("Setting mapped data: ", data);
  };

  const getColumnLength = (array) => {
    if (array.columns) {
      return array.columns.length;
    } else {
      return array.length;
    }
  };

  // optional steps are Transformation and Enrichments
  const isStepOptional = (step) => {
    return optionalSteps.includes(step);
  };

  const isStepCompany = (step) => {
    return step === 0 && customer !== "";
  };

  // track skipped steps
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    stepDone = false;
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    // check if step can be skipped
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    // go to next step and add to list of skipped steps
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setSaveLaneState(false);
    stepDone = false;
    setCustomer("");
    customerSettings("N/A");
    tenderID("");
    fileData({ columns: ["-"] });
    mappedData({ columns: [""] });
  };

  // define step content
  let component;
  if (activeStep === 0) {
    component = (
      <CustomerData func={customerSettings} handleNext={handleNext} />
    );
    if (customer !== "N/A") {
      stepDone = true;
    }
  } else if (activeStep === 1) {
    component = (
      <TenderData
        customer={customer}
        func={tenderID}
        handleNext={handleNext}
        setOpen={setOpen}
        customColumns={customColumns}
        setCustomColumns={setCustomColumns}
      />
    );
    if (tender !== "") {
      stepDone = true;
    }
  } else if (activeStep === 2) {
    component = <FileUpload func={fileData} handleNext={handleNext} />;
    if (getColumnLength(uploadFile) > 1) {
      stepDone = true;
    }
  } else if (activeStep === 3) {
    /*
  else if (activeStep === 3) {
    component = <Transformation />;
    stepDone = true;
  } 
  */
    component = (
      <Mapping
        customColumns={customColumns}
        uploadFile={uploadFile}
        tender={tender}
        func={mappedData}
        handleNext={handleNext}
      />
    );
    if (getColumnLength(mapData) > 1) {
      stepDone = true;
    }
  } else if (activeStep === 4) {
    /*
  else if (activeStep === 5) {
    component = <Enrichments />;
    stepDone = true;
  } 
  */
    component = (
      <Validation
        mapData={mapData}
        tender={tender}
        func={setSaveLaneState}
        handleNext={handleNext}
      />
    );
    if (isPosted(saveLaneState)) {
      stepDone = true;
    }
  } else {
    component = <CustomerData />;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // return stepper for definition of new tender
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Define column name
          </Typography>
          <Grid item xs={12} sx={{ mt: 3 }} align="left"></Grid>
          <TextField
            id="filled-basic"
            onChange={(event) => setColumnTitle(event.target.value)}
            value={columnTitle}
            type="text"
            label="Column Name"
          />
          <Grid item xs={12} sx={{ mt: 3 }} align="left"></Grid>

          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                if (columnTitle) {
                  setCustomColumns([...customColumns, columnTitle]);
                }
                setColumnTitle("");
                setOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={2}>
        {!userType.isAdmin && isAuth && (
          <Grid item xs={12}>
            <Typography>Tender Management only!</Typography>
          </Grid>
        )}
        <Grid item xs={12}></Grid>

        {userType.isAdmin && (
          <Grid container spacing={2} justify="flex-end" alignItems="center">
            {/* NEW */}
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8}>
              <Typography variant="h5" align="left">
                Create your tender in seven steps.
              </Typography>
            </Grid>
            xw
            <Grid item xs={12}></Grid>
            <Divider style={{ width: "100%" }} flexitem />
            <Grid item xs={12}></Grid>
          </Grid>
        )}
        <Grid item xs={0.5}></Grid>
        {userType.isAdmin && (
          <Grid item xs={11}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepCompany(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">
                        Company: {customer}
                      </Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === 5 ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All Steps Completed - You are Done!
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      onClick={handleReset}
                      variant="outlined"
                      size="large"
                    >
                      Enter Next Tender
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ m: 1 }} />
                    {component}
                    <Box sx={{ m: 1 }} />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )}
                    {stepDone && (
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}
                    {!stepDone && (
                      <Button disabled onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}
